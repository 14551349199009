import * as React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import CardComponent from "../card/card";
// markup
const Carousel2 = () => {
  function show() {
    return false;
    /* if (window.innerWidth > 464) {
      return true;
    } else {
      return false;
    }*/
  }
  return (
    <Carousel
      additionalTransfrom={0}
      arrows
      autoPlaySpeed={3000}
      centerMode={show()}
      className=""
      containerClass="container-with-dots"
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={{
        desktop2: {
          breakpoint: {
            max: 3000,
            min: 1569,
          },
          items: 4,
          partialVisibilityGutter: 40,
        },
        desktop: {
          breakpoint: {
            max: 1569,
            min: 1249,
          },
          items: 3,
          partialVisibilityGutter: 40,
        },
        tablet: {
          breakpoint: {
            max: 1249,
            min: 941,
          },
          items: 2,
          partialVisibilityGutter: 30,
        },
        mobile: {
          breakpoint: {
            max: 941,
            min: 0,
          },
          items: 1,
          partialVisibilityGutter: 0,
        },
      }}
      showDots={false}
      sliderClass=""
      slidesToSlide={1}
      swipeable
    >
      <CardComponent />
      <CardComponent />
      <CardComponent />
      <CardComponent />
      <CardComponent />
      <CardComponent />
      <CardComponent />
      <CardComponent />
    </Carousel>
  );
};

export default Carousel2;
