import * as React from "react";
import * as styledComponents from "./card.module.scss";
import { Card } from "react-bootstrap";

// markup
const CardComponent = () => {
  return (
    <Card className={styledComponents._card}>
      <Card.Img
        variant="top"
        src="https://heliotvilchis.com/dakaramerica/static/r1-a8166c78da3f5826e644c267bfbc26b6.jpg"
      />
      <Card.ImgOverlay>
        <Card.Title className={styledComponents.details}>Détails</Card.Title>
      </Card.ImgOverlay>
      <Card.Body>
        <Card.Title>2021 Nissan Rogue</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          MIDNIGHT EDITION
        </Card.Subtitle>
        <Card.Text>
          <p className="text-muted">62,827 km</p>
        </Card.Text>
      </Card.Body>
      <Card.Footer className={styledComponents._cardFooter}>
        283 498 FCFA
      </Card.Footer>
    </Card>
  );
};

export default CardComponent;
