import React from "react";
import { Container } from "react-bootstrap";

import NavBar from "../components/navbar";
import Carousel2 from "../components/carousel/carousel2";
import IndexForm from "../components/forms/indexform";
import Footer from "../components/footer/footer";

import heroimage from "../images/hero.png";
import proposimage from "../images/propos.png";

import * as styledComponents from "../styles/index.module.scss";

// markup
const AutosPage = () => {
  return (
    <>
      <NavBar />
      <Container>
        <img
          className={styledComponents.hero}
          src={heroimage}
          alt="Dakar America"
        />
        <div className={styledComponents.descText}>
          <p>
            <img src={proposimage} alt="À propos de nous" />
            DAKAR AMERICA offre des services de vente voiture de pièces
            automobile et camion nous offrons également le service transport
            maritime Entreposage, Booking, livraison de conteneur, chargement,
            GROUPAGE transport de colis et marchandise. DÉGROUPAGE transfert de
            conteneur sous douane Déclarant en Douane. A travers notre filiale
            Dakar America Export Inc au Canada et Dakar America sas au Sénégal
            nous garantissons a nos client un service continue et une visibilité
            dans leur opération d'importation au Sénégal en provenance de
            l'Amérique du Nord
          </p>
        </div>
        <div className={styledComponents.dakarimages}></div>
        <h2
          style={{
            textAlign: "center",
            fontWeight: "bolder",
            color: "#59452C",
            textShadow: "0 3px 4px #00000040",
            fontSize: "48px",
            margin: "40px 0 20px 0",
          }}
        >
          Véhicules en inventaire
        </h2>
      </Container>
      <Carousel2 />
      <IndexForm />
      <Footer />
    </>
  );
};

export default AutosPage;
